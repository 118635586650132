<template>
  <div class="d-flex align-items-center justify-content-center py-4 px-2 bg-gray-100 vh-100">
    <div class="card-wrapper">
      <div class="card">
        <div class="card-header justify-content-center px-2">
          <div class="card-heading text-primary d-flex">
            <!-- logo -->
            <b-img
              :src="heroleadsLogo"
              alt="Heroleads Logo"
            />
            <h2 class="brand-text text-primary ml-1 mt-2">
              HerovisionX
            </h2>
          </div>
        </div>
        <div class="card-body">
          <h3 class="mb-1">
            Reset Password 🔒
          </h3>
          <p class="text-muted text-sm mb-1">
            Your new password must be different from previously used passwords
          </p>
          <!-- form -->
          <validation-observer ref="observer">
            <b-form
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- password -->
              <b-form-group
                label="New Password"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="newPasswordFieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="newPasswordToggleIcon"
                        @click="toggleNewPasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="confirmPassword"
                      :type="confirmPasswordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="confirmPasswordToggleIcon"
                        @click="toggleConfirmPasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
                class="btn-set-password"
              >
                Set New Password
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'heroai-client-login'}" class="back-to-login-link">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </b-card-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      password: '',
      confirmPassword: '',
      // validation
      required,

      // Toggle Password
      newPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
    }
  },
  computed: {
    heroleadsLogo() {
      return require('@/assets/images/logo/heroleads-logo.png')
    },

    loginPageImage() {
      return require('@/assets/images/pages/login_page_image.svg')
    },

    newPasswordToggleIcon() {
      return this.newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    const { token } = this.$route.query
    if (!token) {
      this.$router.replace({ name: 'page-not-found' })
    }
  },
  methods: {
    toggleNewPasswordVisibility() {
      this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password'
    },

    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
    },

    async validationForm() {
      try {
        const validationResult = await this.$refs.observer.validate()

        if (validationResult) {
          let alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to save new password?' })

          if (alertResult.value) {
            this.showOverlay = true

            // Get reset token from email link
            const { token } = this.$route.query
            const body = {
              password: this.password,
              password_confirmation: this.confirmPassword,
              email: this.userEmail,
              hashed_token: token,
            }

            await axiosInstance.post('client/reset-client-password', body)

            alertResult = await this.$swal({ ...SweetAlert.success, text: 'Password successfully changed' })

            if (alertResult.value) {
              await this.$router.push({ name: 'heroai-client-login' })
            }
          }
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style>
.card-wrapper {
  max-width: 400px;
}

.btn-set-password, .btn-set-password:active, .btn-set-password:focus {
  background-color: #ff3e00 !important;
  border-color: #ff3e00 !important;
  border-radius: 50px;
}

.btn-set-password:hover {
  box-shadow: 0 8px 25px -8px #ff3e00 !important;
}

.back-to-login-link {
  color: #ff3e00 !important;
}
</style>
